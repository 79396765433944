<template>
  <div>
    <b-card>
      <div
        v-if="offer.user"
        class="mb-2"
      >
        <strong
          class="my-2"
        >Suggested by: </strong>
        <b-link :to="{name:'admin-mobile-users-view',params:{id:offer.user.id}}">
          {{ suggestedBy }}
        </b-link>
      </div>

      <div
        v-if="offer.publish_date"
        class="mb-2"
      >
        <strong
          class="my-2"
        >Published at: </strong>
        <span>
          {{ offer.publish_date }}
        </span>
      </div>
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <offers-inputs
            :offer="offer"
            :disabled="$attrs.disabled"
          />
          <b-row>
            <b-col>
              <back class="mr-1" />
            </b-col>
            <b-col>
              <div
                v-if="!$attrs.disabled"
                class="text-right"
              >
                <template v-if="offer.suggestion_status==='pending'">
                  <b-button
                    class="mr-1"
                    variant="danger"
                    @click="showDeclineModal"
                  >
                    Decline
                  </b-button>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="toggleSuggest('approved')"
                  >
                    Approve
                  </b-button>
                </template>
                <submit-button v-bind="{handleSubmit,submit}" />
              </div>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-card>

    <b-modal
      id="reason-modal"
      centered
      title="Reason"
      hide-footer
    >
      <reason-modal
        @submit-reason="handleDecline"
      />
    </b-modal>

    <!-- Offer Tag Modal -->
    <domain-item-form
      id="add-offer-tag"
      title="Add Offer Tag"
      :item="tag"
      :reset-item="resetTag"
      :submit="addTagRequest"
      :is-status-enabled="false"
    />

    <!-- Offer Tag Modal -->
    <domain-item-form
      id="add-offer-category"
      title="Add Offer Category"
      :item="category"
      :reset-item="resetCategory"
      :submit="addCategoryRequest"
      :is-status-enabled="false"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Offers from '@/common/compositions/Offers/OffersApi'
import ReasonModal from '@/common/components/Offers/ReasonModal.vue'
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import DomainItemForm from '../GeneralSettings/domain-items/DomainItemForm.vue'
import OffersInputs from './OfferInputs.vue'

export default {
  name: 'OfferForm',
  components: {
    OffersInputs, SubmitButton, Back, DomainItemForm, ReasonModal,
  },
  props: {
    offer: { type: Object, default: () => {} },
    submit: { type: Function, default: null },
  },
  data() {
    return {
      toggleData: {},
    }
  },
  computed: {
    suggestedBy() {
      return `${this.offer.user?.profile?.first_name} ${this.offer.user?.profile?.last_name}`
    },
  },
  setup() {
    const {
      item: tag, addItemRequest: addTagRequest, resetItem: resetTag,
    } = domainItemApi('activities', '/internalops/offer/tag')

    const { successfulOperationAlert } = handleAlerts()
    const { listSuggestedOffersRequest, toggleSuggestedOffersRequest } = Offers()
    const {
      item: category, addItemRequest: addCategoryRequest, resetItem: resetCategory,
    } = domainItemApi('activities', '/internalops/offer/category')

    return {
      tag, addTagRequest, resetTag, category, addCategoryRequest, resetCategory, successfulOperationAlert, listSuggestedOffersRequest, toggleSuggestedOffersRequest,
    }
  },
  methods: {
    toggleSuggest(newStatus) {
      this.toggleData.suggest_status = newStatus
      this.toggleSuggestedOffersRequest(this.offer.id, this.toggleData).then(() => {
        const message = newStatus === 'approved' ? 'Offer is approved successfully' : 'Offer is declined successfully'
        this.successfulOperationAlert(message)
        this.offer.suggestion_status = newStatus
      })
    },
    showDeclineModal() {
      this.$bvModal.show('reason-modal')
    },
    handleDecline(reason) {
      this.toggleData.reject_reason = reason
      this.toggleSuggest('decline')
      this.$bvModal.hide('reason-modal')
    },
  },
}
</script>
<style lang="">

</style>
