<template>
  <custom-vue-select
    :id="id"
    :label="label"
    text-field="name"
    :multiple="multiple"
    :rules="rules"
    :placeholder="placeholder"
    :value-reducer="option=>option"
    :disabled="$attrs.disabled"
    :options="options"
    :data-provider="getItems"
    :selected.sync="itemsProxy"
    :close-on-select="!multiple"
  />
</template>
<script>
import CustomVueSelect from '../../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'DomainItemsSelect',
  components: { CustomVueSelect },
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    rules: { type: String, default: '' },
    placeholder: { type: String, default: 'Type 3 chars to search' },
    items: { type: [Array, Object], default: () => [] },
    getItemsRequest: { type: Function, default: () => {} },
    category: { type: String, default: null },
    multiple: { type: Boolean, default: true },
  },
  data() {
    return {
      options: [],
    }
  },
  computed: {
    itemsProxy: {
      get() {
        return this.items
      },
      set(value) {
        this.$emit('update:items', value)
      },
    },
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems(query) {
      this.getItemsRequest({ name: query, status: true, category: this.category }).then(res => {
        this.options = res.data
      })
    },
  },
}
</script>
