import axiosInstances from '@/libs/axios-instances'
import { computed, ref } from '@vue/composition-api'
import handleFormData from '../common/handleFormData'

export default function Offers() {
  const { formData, setFormData } = handleFormData()
  const offer = ref({
    status: true,
  })

  const formattedOffer = computed(() => ({
    ...offer.value,
    image: offer.value.image[0],
    attachment: offer.value.attachment?.[0] instanceof File ? offer.value.attachment?.[0] : '',
    offer_category_id: offer.value.category?.id,
    offer_tag_ids: offer.value.offerTags?.map(tag => tag.id),
  }))

  const listOffersRequest = params => axiosInstances.activities.get('/internalops/offers', { params }).then(res => res)

  const getOfferRequest = id => axiosInstances.activities.get(`/internalops/offers/${id}`).then(res => res)

  const getOriginalOfferRequest = id => axiosInstances.activities.get(`/internalops/suggest-offers/offer/${id}`).then(res => res)

  const listFeedbackReports = id => axiosInstances.activities.get(`/internalops/offer/${id}/report`).then(res => res)

  const toggleReportStatusRequest = (offerId, reportId) => axiosInstances.activities.post(`/internalops/offer/${offerId}/report/${reportId}`).then(res => res)

  const prepareFormData = () => {
    setFormData(formattedOffer.value)
  }

  const addOfferRequest = () => axiosInstances.activities.post('/internalops/offers', formData.value).then(res => res)

  const editOfferRequest = id => axiosInstances.activities.post(`/internalops/offers/${id}?_method=PUT`, formData.value).then(res => res)

  const deleteOfferRequest = id => axiosInstances.activities.delete(`/internalops/offers/${id}`).then(res => res)

  const listSuggestedOffersRequest = params => axiosInstances.activities.get('/internalops/offers', { params }).then(res => res)

  const toggleSuggestedOffersRequest = (offerId, params) => axiosInstances.activities.post(`/internalops/offers/${offerId}/suggest-status`, params).then(res => res)

  const getOfferStatistics = () => axiosInstances.activities.get('/internalops/offers-statistics').then(res => res)

  return {
    offer,
    prepareFormData,
    listOffersRequest,
    getOfferRequest,
    addOfferRequest,
    editOfferRequest,
    deleteOfferRequest,
    listFeedbackReports,
    toggleReportStatusRequest,
    listSuggestedOffersRequest,
    toggleSuggestedOffersRequest,
    getOriginalOfferRequest,
    getOfferStatistics,
  }
}
