<template>
  <fieldset :disabled="$attrs.disabled">
    <div class="p-1 border mb-2">
      <h4 class="my-1">
        Offer Details
      </h4>

      <b-form-row>
        <b-col md="6">
          <!-- Title -->
          <text-input
            id="offer-title"
            name="Offer Title"
            rules="required|max:200"
            :text.sync="offer.title"
          />
        </b-col>
        <!-- Image -->
        <b-col cols="12">
          <div>
            <upload-files
              v-if="!$attrs.disabled"
              id="offer-image"
              label="Upload Image"
              accept="image/jpeg, image/png"
              :cropper="true"
              :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              :rules="{
                ext: ['jpeg', 'png', 'jpg'],
                required: isAddForm,
                size: 10240,
              }"
              :files.sync="offer.image"
            />
            <div>
              <images-preview :images.sync="offer.image" />
            </div>
          </div>
          <div class="mb-2">
            <b-form-row class="text-warning mt-2">
              <b-col md="3">
                <li>Image ratio: 1/1</li>
              </b-col>
              <b-col md="3">
                <li>Maximum file size: 10MB</li>
              </b-col>
              <b-col md="3">
                <li>Supported image files: JPEG or PNG</li>
              </b-col>
            </b-form-row>
          </div>
        </b-col>
        <!-- Offer Category -->
        <b-col
          md="6"
          class="mb-1"
        >
          <offer-category-select
            rules="required"
            :disabled="$attrs.disabled"
            :offer-category.sync="offer.category"
          />
          <b-link v-b-modal.add-offer-category>
            Add Offer Category
          </b-link>
        </b-col>
        <!-- Offer Tag -->
        <b-col
          md="6"
          class="mb-1"
        >
          <offer-tag-select
            title="Offer tag"
            rules="required"
            multiple
            :disabled="$attrs.disabled"
            :offer-tag.sync="offer.offerTags"
          />
          <b-link v-b-modal.add-offer-tag>
            Add Offer Tag
          </b-link>
        </b-col>
        <b-col md="6">
          <date-time
            id="publish-date"
            :datetime.sync="offer.publish_date"
            name="Publish Date"
            rules="required"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              minDate: 'today',
            }"
          />
        </b-col>
        <!-- Offer Start Date -->
        <b-col md="6">
          <date-time
            id="offer-start-date"
            :datetime.sync="offer.start_date"
            name="Start Date"
            rules="required"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              minDate: 'today',
            }"
          />
        </b-col>
        <!-- Offer End Date -->
        <b-col md="6">
          <date-time
            id="offer-end-date"
            :datetime.sync="offer.end_date"
            name="End Date"
            rules="required"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              minDate: offer.start_date,
            }"
          />
        </b-col>
        <!-- Description -->
        <b-col cols="12">
          <text-area-input
            id="offer-description"
            rules="max:500"
            name="Description"
            :text.sync="offer.description"
          />
        </b-col>
        <!-- Attachment -->
        <b-col cols="12">
          <upload-files
            v-if="!$attrs.disabled"
            id="offer-attachment"
            label="Upload Attachment"
            accept="image/png, image/jpeg, application/pdf"
            rules="ext:png,jpeg,jpg,pdf"
            :files.sync="offer.attachment"
          />

          <images-preview
            v-if="isImage(offer.attachment)"
            :images="offer.attachment"
          />
          <files-preview
            v-else
            :disabled="$attrs.disabled"
            :files="offer.attachment"
          />
        </b-col>
      </b-form-row>
    </div>

    <div class="p-1 border mb-2">
      <h4 class="my-1">
        Offer Source Details
      </h4>
      <b-form-row>
        <!-- Store Name -->
        <b-col md="6">
          <text-input
            id="offer-store-name"
            name="Store Name"
            rules="required|max:200"
            :text.sync="offer.store_name"
          />
        </b-col>
        <!-- Store Category -->
        <b-col md="6">
          <text-input
            id="offer-store-category"
            name="Store Category"
            rules="max:200"
            :text.sync="offer.store_category"
          />
        </b-col>
        <!-- Offer URL -->
        <b-col md="6">
          <text-input
            id="offer-url"
            name="Offer URL"
            rules="url"
            :text.sync="offer.offer_url"
          />
        </b-col>
        <!-- Store URL -->
        <b-col md="6">
          <text-input
            id="store-url"
            name="Store URL"
            rules="url"
            :text.sync="offer.store_website"
          />
        </b-col>
        <!-- Contact Person Name -->
        <b-col md="6">
          <text-input
            id="offer-contact-person-name"
            name="Contact Person Name"
            rules="max:200"
            :text.sync="offer.contact_person_name"
          />
        </b-col>
        <!-- Contact Person Phone -->
        <b-col md="6">
          <text-input
            id="offer-contact-person-phone"
            name="Contact Person Phone"
            rules="phone|max:9"
            :text.sync="offer.contact_person_phone"
          />
        </b-col>
        <b-col md="6">
          <text-input
            id="facebook"
            name="Facebook"
            :text.sync="offer.facebook"
          />
        </b-col>
        <b-col md="6">
          <text-input
            id="twitter"
            name="Twitter"
            :text.sync="offer.twitter"
          />
        </b-col>
        <b-col md="6">
          <text-input
            id="instagram"
            name="Instagram"
            :text.sync="offer.instagram"
          />
        </b-col>
        <b-col
          cols="12"
          class="border p-1 my-1"
        >
          <h4 class="mb-2">
            Location
          </h4>
          <location-inputs :details="offer" />
        </b-col>
        <!-- Status -->
        <b-col cols="12">
          <status-radio :status.sync="offer.status" />
        </b-col>
      </b-form-row>
    </div>
  </fieldset>
</template>
<script>
import Helpers from '@/common/compositions/common/Helpers'
import DateTime from '../common/FormInputs/DateTime.vue'
import FilesPreview from '../common/FormInputs/FilesPreview.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import StatusRadio from '../common/FormInputs/StatusRadio.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'
import OfferCategorySelect from '../GeneralSettings/offer-categories/OfferCategorySelect.vue'
import OfferTagSelect from '../GeneralSettings/offer-tags/OfferTagSelect.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'

export default {
  name: 'OffersInputs',
  components: {
    TextInput,
    UploadFiles,
    OfferCategorySelect,
    OfferTagSelect,
    DateTime,
    TextAreaInput,
    FilesPreview,
    StatusRadio,
    ImagesPreview,
    LocationInputs,
  },
  props: {
    offer: { type: Object, default: () => {} },
  },
  computed: {
    isAddForm() {
      return !this.$route.params.id
    },
  },
  setup() {
    const { isImage } = Helpers()
    return { isImage }
  },
}
</script>
<style lang="scss">
</style>
