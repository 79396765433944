<template>
  <b-list-group>
    <b-list-group-item
      v-for="(file,index) in files.filter(file=>file)"
      :key="index"
    >
      <b-row>
        <b-col>
          <feather-icon
            icon="FileTextIcon"
            size="20"
            class="mr-1"
          />

          <b-link
            v-if="isFile(file)"
            :href="blobs[index]"
            target="blank"
          >
            {{ file.name }}
          </b-link>

          <b-link
            v-else-if="isFileAnImage(file)"
            :href="file"
            target="blank"
          >
            {{ file.name || 'File' }}
          </b-link>

          <b-link
            v-else
            :href="`https://docs.google.com/viewerng/viewer?url=${file}`"
            target="blank"
          >
            {{ file.nickname || 'File' }}
          </b-link>
        </b-col>

        <b-col
          v-if="!disabled"
          class="text-right"
        >
          <feather-icon
            role="button"
            class="text-danger rounded-border"
            icon="XIcon"
            size="20"
            @click="deleteFile(index,file)"
          />
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Helpers from '@/common/compositions/common/Helpers'

export default {
  name: 'FilesPreview',
  props: {
    files: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      blobs: [],
    }
  },
  setup() {
    const { isImage } = Helpers()
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return { isImage, successfulOperationAlert, confirmOperation }
  },
  watch: {
    files(files) {
      this.convertFilesToBlobs(files)
    },
  },
  mounted() {
    this.convertFilesToBlobs(this.files)
  },
  beforeDestroy() {
    this.blobs.forEach(ele => {
      URL.revokeObjectURL(ele)
    })
  },
  methods: {
    convertFilesToBlobs(files) {
      files.forEach((ele, index) => {
        this.$set(this.blobs, index, this.convertFileToBlob(ele))
      })
    },
    convertFileToBlob(file) {
      return this.isFile(file) ? URL.createObjectURL(file) : file
    },
    isFile(file) {
      return file instanceof File
    },
    deleteFile(index, file) {
      this.confirmOperation(`Are you sure you want to delete this file ${file.nickname}`).then(() => {
        this.$emit('delete', index)
        this.$delete(this.files, index)
      })
    },
    isFileAnImage(file) {
      return this.isImage(file.type) || this.isImage(file)
    },
  },
}
</script>
<style lang="scss">
.rounded-border {
  padding: 2px;
  border-radius: 50%;
}
</style>
