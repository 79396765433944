<template>
  <div>
    <domain-items-select
      id="offer-tags-select"
      :label="title"
      :multiple="$attrs.multiple"
      :rules="rules"
      placeholder="Offer Tags"
      :disabled="$attrs.disabled"
      :items.sync="offerTagProxy"
      :get-items-request="getItemsRequest"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import DomainItemsSelect from '../domain-items/DomainItemsSelect.vue'

export default {
  name: 'OfferTagSelect',
  components: { DomainItemsSelect },
  props: {
    title: { type: String, default: () => '' },
    offerTag: { type: Array, default: () => [] },
    rules: { type: String, default: '' },
  },
  computed: {
    offerTagProxy: {
      get() {
        return this.offerTag
      },
      set(value) {
        this.$emit('update:offerTag', value)
      },
    },
  },
  setup() {
    const { getItemsRequest } = domainItemApi('activities', '/internalops/offer/tag')
    return { getItemsRequest }
  },
}
</script>
<style lang="">

</style>
