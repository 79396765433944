<template>
  <div>
    <domain-items-select
      id="offer-categories-select"
      label="Offer category"
      :multiple="false"
      :disabled="$attrs.disabled"
      :rules="rules"
      :items.sync="offerCategoryProxy"
      :get-items-request="getItemsRequest"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import DomainItemsSelect from '../domain-items/DomainItemsSelect.vue'

export default {
  name: 'OfferCategorySelect',
  components: { DomainItemsSelect },
  props: {
    offerCategory: { type: [Array, Object], default: () => [] },
    rules: { type: String, default: '' },
  },
  computed: {
    offerCategoryProxy: {
      get() {
        return this.offerCategory
      },
      set(value) {
        this.$emit('update:offerCategory', value)
      },
    },
  },
  setup() {
    const { getItemsRequest } = domainItemApi('activities', '/internalops/offer/category')
    return { getItemsRequest }
  },
}
</script>
<style lang="">

</style>
